import Button from "@ingka/button";
import React from "react";
import { SizedText } from "../../styles/styles";
import { Article } from "../../types/article";
import { useTasksByArticle } from "../../hooks/useTasksByArticle";
import { useStoreId, useUserId } from "../../../../core/auth/useLoggedInUser";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import { setOngoingTasksToClosed } from "../../tools";
import { useWorkspacesAction } from "../../../../hooks/useWorkspaces";
import { Trans, useTranslation } from "@coworker/locales";
import { useDeleteArticlesByIds } from "../../hooks/useDeleteArticlesByIds";
import { useToastNotification } from "@coworker/components";

interface DeleteArticleModalProps {
  modalOpen: boolean;
  article: Article;
  onModalOpenChange: (modalOpen: boolean) => void;
  postDeleteFunction?: () => Promise<void>;
}

export const DeleteArticleModal = ({
  modalOpen,
  article,
  onModalOpenChange,
  postDeleteFunction,
}: DeleteArticleModalProps) => {
  const { showToast } = useToastNotification();
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const uid = useUserId();
  const storeId = useStoreId();
  const tasks = useTasksByArticle(storeId, article.productArticleId);
  const deleteArticles = useDeleteArticlesByIds(article.roomId);

  const handleDeleteArticle = async () => {
    await setOngoingTasksToClosed(
      article.roomId,
      t("taskClosedBecauseRemovedArticleString"),
      tasks,
      uid,
      storeId
    );
    try {
      await deleteArticles.mutateAsync([article.id]);
      if (postDeleteFunction) await postDeleteFunction();
    } catch (error) {
      alert(t("backendOperationFailedString"));
    }
    onModalOpenChange(!modalOpen);
    push(`/roomsettings/${article.roomId}`);
    showToast(t("articleDeletedString"));
  };

  return (
    <>
      <Modal
        visible={modalOpen}
        handleCloseBtn={() => onModalOpenChange(false)}
      >
        <Prompt
          title={""}
          titleId={`delete-${article.id}`}
          header={
            <ModalHeader
              title={t("deleteArticleString")}
              ariaCloseTxt="Close prompt."
            />
          }
          footer={
            <ModalFooter>
              <Button
                text={t("cancelString")}
                type="secondary"
                onClick={() => onModalOpenChange(false)}
              />
              <Button
                text={t("deleteString")}
                type="danger"
                onClick={handleDeleteArticle}
              />
            </ModalFooter>
          }
        >
          <>
            <SizedText $fontSize={16} $bold={true}>
              <Trans values={{ item: article.itemName }}>
                surelyDeleteString
              </Trans>
            </SizedText>
            <SizedText $fontSize={14} $bold={false}>
              <Trans>deleteRoomSettingTasksClosedString</Trans>{" "}
              <Trans>canNotBeUndoneString</Trans>
            </SizedText>
          </>
        </Prompt>
      </Modal>
    </>
  );
};
