import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import React from "react";
import { useTranslation } from "@coworker/locales";
import { Room } from "../types/room";
import { AggregatedArticleListing } from "./AggregatedArticleListing";
import { GroupedArticleListing } from "./GroupedArticleListing";
import { useActiveArticlesByRoom } from "../hooks/useActiveArticlesByStore";

interface RoomTabsProps {
  room: Room;
}

export const RoomTabs = ({ room }: RoomTabsProps) => {
  const { t } = useTranslation();

  const { activeArticles } = useActiveArticlesByRoom(room.id || "");

  if (!activeArticles) return <></>;

  return (
    <Tabs
      defaultActiveTab="article-list-tab"
      tabs={[
        <Tab
          key={"article-list-tab"}
          tabPanelId="article-list-tab"
          text={t("articleListString")}
        />,
        <Tab
          key={"groups-tab"}
          tabPanelId="groups-tab"
          text={t("groupsString")}
        />,
      ]}
      tabPanels={[
        <TabPanel key={"article-list-tab-panel"} tabPanelId="article-list-tab">
          <AggregatedArticleListing articles={activeArticles} room={room} />
        </TabPanel>,

        <TabPanel key={"groups-tab-panel"} tabPanelId="groups-tab">
          <GroupedArticleListing articles={activeArticles} room={room} />
        </TabPanel>,
      ]}
    />
  );
};
