import React from "react";
import { Articles } from "../types/article";
import { Room } from "../types/room";

interface AggregatedArticleListingProps {
  articles: Articles;
  room: Room;
}

export const AggregatedArticleListing = ({
  articles,
  room,
}: AggregatedArticleListingProps) => {
  console.log("AggregatedArticleListing", { articles, room });
  return <></>;
};
