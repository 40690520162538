import React from "react";
import { Articles } from "../types/article";
import { Room } from "../types/room";
import { useGroupsByRoomId } from "../hooks/useGroupsByRoomId";
import { ArticleGroupItem } from "../Molecules/ArticleGroupItem";
import { ListWrapper } from "../styles/styles";

interface GroupedArticleListingProps {
  articles: Articles;
  room: Room;
}

export const GroupedArticleListing = ({
  articles,
  room,
}: GroupedArticleListingProps) => {
  console.log("GroupedArticleListingProps", { articles, room });

  const { data: groups, isLoading } = useGroupsByRoomId(room.id ?? "");

  if (isLoading || !groups) {
    return <></>;
  } else {
    console.log("Groups", groups);

    return (
      <ListWrapper>
        {groups.map((group, index) => (
          <ArticleGroupItem
            key={`${room.id}-${group.id}-${index}`}
            group={group}
            articles={articles}
          />
        ))}
      </ListWrapper>
    );
  }
};
