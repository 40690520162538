import { useMemo } from "react";
import statusTypes from "@coworker/functions/src/enums/statusTypes.json";
import { useArticlesByRoomId } from "./useArticlesByRoomId";

export const useActiveArticlesByRoom = (roomId: string) => {
  const { data: articles, isLoading } = useArticlesByRoomId(roomId);
  const activeArticles = useMemo(() => {
    return articles?.filter((article) => article.active === statusTypes.ACTIVE);
  }, [articles]);
  return { activeArticles, isLoading };
};
