import React from "react";
import { useTranslation } from "@coworker/locales";
import { useParams } from "react-router";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { FullWidthButton } from "../../SkapaButton";
import { fullRoomName } from "../tools";
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import SSRIcon from "@ingka/ssr-icon";
import SettingsIcon from "@ingka/ssr-icon/paths/gear";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useRoomById } from "../hooks/useRoomById";
import { RoomViewSkeleton } from "../Skeletons/RoomViewSkeleton";
import { useImagesByRoomId } from "../hooks/useImagesByRoomId";
import { RoomImagesCarousel } from "../Molecules/RoomImagesCarousel";
import styled from "styled-components";
import Image from "@ingka/image";
import { UploadImageButton } from "../Molecules/UploadImageButton";
import { RoomTabs } from "../Organisms/RoomTabs";

const SVGResizer = styled.div`
  .image__icon {
    width: 140px;
    height: 140px;
  }
`;

export const TabbedRoomView = () => {
  const { roomId } = useParams();
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();

  const { data: room } = useRoomById(roomId || "");
  const { data: roomImages } = useImagesByRoomId(roomId || "");

  const appBarActions: AppBarAction[] = [
    {
      name: "settings",
      icon: <SSRIcon paths={SettingsIcon} />,
      onClick: () => push(`/roomsettings/${room?.id}/settings`),
      position: "right",
    },
  ];

  const roomHeader = fullRoomName(
    room?.name,
    room?.main_article_name_1,
    room?.main_article_name_2
  );
  if (!room)
    return (
      <FullScreenPopup
        appBarConfig={{
          title: roomHeader,
          actions: appBarActions,
          onBack: () => {
            push("/roomsettings");
          },
        }}
        noPadding
      >
        <RoomViewSkeleton />
      </FullScreenPopup>
    );

  return (
    <FullScreenPopup
      isActionBarHiddenOnScroll={false}
      actionBarContent={<FullWidthButton text={t("continueString")} />}
      appBarConfig={{
        title: roomHeader,
        actions: appBarActions,
        onBack: () => {
          push("/roomsettings");
        },
      }}
      noPadding
    >
      <>
        {roomImages && roomImages.length > 0 ? (
          <RoomImagesCarousel room={room} />
        ) : (
          <div style={{ position: "relative", display: "inline-block" }}>
            {roomImages && roomImages.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  top: "80%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                <SVGResizer>
                  <Image />
                </SVGResizer>
                <UploadImageButton
                  roomId={room.id}
                  text={t("addPhotoString")}
                />
              </div>
            )}
          </div>
        )}
        <RoomTabs room={room} />
      </>
    </FullScreenPopup>
  );
};
