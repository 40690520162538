import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import { formatProductIdWithDots } from "@coworker/reusable";
import { issueTypeDescriptions } from "../constants/issueTypeDescriptions";
import { describeTheProblemList } from "../constants/describeTheProblemList";
import { getTranslationKeyFromFlags } from "@coworker/app/src/components/RoomSettings/tools";
import { Task } from "@coworker/types/lib/tasks/base";
import { AddonTask } from "@coworker/types/lib/tasks/addon";
import { ProductQualityTask } from "@coworker/types/lib/tasks/product_quality";
import { ProductIssueTask } from "@coworker/types/lib/tasks/product_issue";
import { MFAQFollowUp } from "@coworker/types/lib/tasks/mfaq_followup";
import { TestBuyFollowUp } from "@coworker/types/lib/tasks/testbuy_followup";
import { RoomSettings } from "@coworker/types/lib/tasks/room_settings";
import { CustomTask } from "@coworker/types/lib/tasks/custom";

export const getTaskInfo = (
  task: Task,
  t: (key?: string) => string,
  productUnitTitle: string
) => {
  let type, title, subtitle;

  switch (task.task_type) {
    case taskTypeOptions.ADDON:
      const addonTask = task as AddonTask;
      const isItemNameMissing =
        addonTask.item_name === null ||
        addonTask?.item_name === "MISSING_PRODUCT_NAME";
      type =
        addonTask.picked ||
        addonTask.state === taskStateOptions.COMPLETED ||
        addonTask.state === taskStateOptions.CLOSED
          ? t("refillString")
          : t("addonTitleString");
      title = `${
        isItemNameMissing ? "" : addonTask.item_name + " • "
      }${formatProductIdWithDots(addonTask.product_article_id)}`;
      subtitle =
        task.state === taskStateOptions.COMPLETED
          ? addonTask.refilled_quantity === addonTask.actual_requested_quantity
            ? `${addonTask.actual_requested_quantity} ${productUnitTitle} ${t(
                "itemsToString"
              )} ${addonTask.location_grid_code}`
            : `${addonTask.actual_requested_quantity} → ${
                addonTask.refilled_quantity
              } ${productUnitTitle} ${t("itemsToString")} ${
                addonTask.location_grid_code
              }`
          : `${addonTask.actual_requested_quantity} ${productUnitTitle} ${t(
              "itemsFromString"
            )}
       ${
         addonTask.pickup_location_custom
           ? addonTask.pickup_location_custom
           : addonTask.pickup_location_grid_code
       } ${t("itemsToString")} ${
              addonTask.location_custom
                ? addonTask.location_custom
                : addonTask.location_grid_code
            }`;
      break;

    case taskTypeOptions.PRODUCT_QUALITY:
      const productQualityTask = task as ProductQualityTask;
      type = t("productQualityReportString");
      title = t(
        describeTheProblemList.find(
          (problem) =>
            problem.type === productQualityTask.pq_describe_the_problem_type
        )?.transKey
      );
      subtitle = `${productQualityTask.item_name} • ${formatProductIdWithDots(
        productQualityTask.product_article_id
      )}`;
      break;

    case taskTypeOptions.PRODUCT_ISSUE:
      const productIssueTask = task as ProductIssueTask;
      type = t("taskTypeTitleProductIssue");
      title = t(
        issueTypeDescriptions.find(
          (issue) => issue.type === productIssueTask.issue_type
        )?.transKey
      );
      subtitle = `${productIssueTask.item_name} • ${formatProductIdWithDots(
        productIssueTask.product_article_id
      )}`;
      break;

    case taskTypeOptions.MFAQ_FOLLOW_UP:
      const mfaqTask = task as MFAQFollowUp;
      type = t("mfaqFollowUpTitle");
      title = mfaqTask.question_text;
      subtitle = `${
        mfaqTask.question_area?.includes("depts")
          ? `HFB${mfaqTask.question_area?.split("=")[1]}:`
          : mfaqTask.question_area?.includes("whole")
          ? `${t("wholeStoreString")}:`
          : `${mfaqTask.question_area?.split("=")[1]}:`
      } ${mfaqTask.description}`;
      break;

    case taskTypeOptions.TESTBUY_FOLLOW_UP:
      const testbuyTask = task as TestBuyFollowUp;
      type = t("testBuyFollowUpString");
      title = `${testbuyTask.item_name} • ${formatProductIdWithDots(
        testbuyTask.product_article_id
      )}`;
      subtitle = `${t("atString")} ${testbuyTask.location}, ${
        testbuyTask.failedCriteria?.length
      } ${t("criteraFailedString")}`;
      break;

    case taskTypeOptions.ROOM_SETTINGS:
      const roomSettingsTask = task as RoomSettings;
      type = t("roomSettingsString");
      title = `${t(getTranslationKeyFromFlags(roomSettingsTask))} ${
        roomSettingsTask.room_name
      }`;
      subtitle = `${roomSettingsTask.item_name} • ${formatProductIdWithDots(
        roomSettingsTask.product_article_id
      )}`;
      break;

    case taskTypeOptions.CUSTOM:
      const customTask = task as CustomTask;
      const isScheduledTask =
        customTask.planned || customTask.repeatable_task_id;
      type = isScheduledTask ? t("scheduledTaskString") : t("customString");
      title = customTask.custom_title;
      subtitle = customTask.description;
      break;

    case taskTypeOptions.PLANNED:
      const plannedTask = task as CustomTask;
      type = t("scheduledTaskString");
      title = plannedTask.custom_title;
      subtitle = plannedTask.description;
      break;
  }

  return { type, title, subtitle };
};
