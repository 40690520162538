import React, { useState } from "react";
import SSRIcon from "@ingka/ssr-icon";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import {
  FlexContainer,
  LeftContainer,
  ListItemContainer,
  RightContainer,
  SizedText,
  TextStyle,
} from "../../styles/styles";
import { useTranslation, Trans } from "@coworker/locales";
import { useTasksByRoom } from "../../hooks/useTasksByRoom";
import {
  filterOngoingTasksByRoomId,
  setOngoingTasksToClosed,
} from "../../tools";
import { useStoreId, useUserId } from "../../../../core/auth/useLoggedInUser";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import Button from "@ingka/button";
import { deleteRoom } from "../../services/rooms.service";
import { useWorkspacesAction } from "../../../../hooks/useWorkspaces";
import { Room } from "../../types/room";
import { useToastNotification } from "@coworker/components";

interface DeleteRoomModalProps {
  room: Room;
}

export const DeleteRoomModal = ({ room }: DeleteRoomModalProps) => {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const { showToast } = useToastNotification();

  const uid = useUserId();
  const storeId = useStoreId();
  const tasks = useTasksByRoom(room?.id || "");

  const [modalOpen, setModalOpen] = useState(false);

  const handleDeleteRoom = async () => {
    if (filterOngoingTasksByRoomId(tasks.tasks, room.id).length > 0) {
      await setOngoingTasksToClosed(
        room.id,
        t("taskClosedBecauseRemovedRoomString"),
        tasks,
        uid,
        storeId
      );
    }
    await deleteRoom(room.id);
    setModalOpen(false);
    push("/roomsettings");
    showToast(t("roomDeletedString"));
  };

  if (!room) return <></>;

  return (
    <>
      <ListItemContainer onClick={() => setModalOpen(!modalOpen)}>
        <LeftContainer>
          <TextStyle
            $bold={false}
            $italic={false}
            $size={"medium"}
            $color="pink"
          >
            <Trans>deleteRoomString</Trans>
          </TextStyle>
        </LeftContainer>
        <RightContainer>
          <SSRIcon paths={trashCan} color="var(--pink)" />
        </RightContainer>
      </ListItemContainer>

      <Modal
        visible={modalOpen}
        handleCloseBtn={() => setModalOpen(!modalOpen)}
      >
        <Prompt
          title={""}
          titleId={`delete-${room.id}`}
          header={
            <ModalHeader
              title={t("deleteRoomString")}
              ariaCloseTxt="Close prompt."
            />
          }
          footer={
            <ModalFooter>
              <FlexContainer $direction={"row"}>
                <Button
                  text={t("cancelString")}
                  type="secondary"
                  onClick={() => setModalOpen(!modalOpen)}
                />
                <Button
                  text={t("deleteString")}
                  type="danger"
                  onClick={handleDeleteRoom}
                />
              </FlexContainer>
            </ModalFooter>
          }
        >
          <>
            <SizedText $fontSize={16} $bold={true}>
              <Trans values={{ item: room.name }}>
                surelyDeleteRoomSettingString
              </Trans>
            </SizedText>
            <SizedText $fontSize={14} $bold={false}>
              <Trans>deleteRoomSettingTasksClosedString</Trans>{" "}
              <Trans>canNotBeUndoneString</Trans>
            </SizedText>
          </>
        </Prompt>
      </Modal>
    </>
  );
};
