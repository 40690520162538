import React from "react";
import {
  SkapaQuantityStepper,
  SkapaQuantityStepperProps,
} from "../../SkapaQuantityStepper";
import Button from "@ingka/button";
import switchArticle from "@ingka/ssr-icon/paths/arrow-left-arrow-right";
import trashcan from "@ingka/ssr-icon/paths/trash-can";
import { DeleteArticleModal } from "../Organisms/Modals/DeleteArticleModal";
import { Article, ArticleInfo, ArticlesInfo } from "../types/article";
import { fetchArticlesInfo } from "../../../hooks/API/tasks.service.helper";
import { useInputPopup } from "../../InputPopup";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateArticle } from "../hooks/useUpdateArticle";
import { mergeArticleInfoAndArticle } from "../types/mappers";
import { createAutoGeneratedTaskAsCompleted } from "./ArticleListItemActionRow.helper";
import { Reasons } from "../constants";
import { Room } from "../types/room";
import { useUserId } from "../../../core/auth/useLoggedInUser";
import { Trans, useTranslation } from "@coworker/locales";
import { useCreateTask } from "../../../hooks/TaskActions/useCreateTask";
import { logEvent } from "../../../helpers/tracker";
import { useToastNotification } from "@coworker/components";

interface ArticleListItemActionRowProps extends SkapaQuantityStepperProps {
  stopPropagation?: (doStop: boolean) => void;
  article: Article;
  selectArticleIdsMode: boolean;
  room?: Room | undefined | null;
  onArticleUpdate?: (article: Article, toSkeleton: boolean) => void;
}

export const ArticleListItemActionRow: React.FC<
  ArticleListItemActionRowProps
> = ({
  stopPropagation,
  article,
  selectArticleIdsMode,
  room,
  onArticleUpdate,
  ...QSProps
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation(); // This stops the click event from propagating to parent elements
  };

  const { showToast } = useToastNotification();
  const { getInput } = useInputPopup();
  const updateArticle = useUpdateArticle(article.id);
  const queryClient = useQueryClient();
  const { call: createTask } = useCreateTask();
  const userId = useUserId();
  const { t } = useTranslation();

  const [trashcanModalOpen, setTrashcanModalOpen] = React.useState(false);

  const createAutoGeneratedTaskWithReason = async (
    reason: Reasons,
    productArticleId: string
  ) => {
    if (!room) return;
    await createAutoGeneratedTaskAsCompleted(
      userId,
      reason,
      room.id,
      room.name,
      room.store_id,
      article,
      productArticleId,
      t("taskIsAutoCompletedNoteString"),
      createTask
    );
  };

  const handleReplaceArticle = async () => {
    const scanResult = await getInput("scansearch", {
      start: new Date(),
    });

    if (!!scanResult?.product) {
      onArticleUpdate && onArticleUpdate(article, true);

      const articlesInfo: ArticlesInfo = await fetchArticlesInfo(
        scanResult.product
      );

      if (articlesInfo.length > 0) {
        const newArticle = mergeArticleInfoAndArticle(
          article,
          articlesInfo[0] as ArticleInfo
        );
        await updateArticle.mutateAsync(newArticle);

        await createAutoGeneratedTaskWithReason(
          Reasons.REPLACE_PRODUCT,
          newArticle.productArticleId
        );

        queryClient.invalidateQueries(["articlesByRoom"]);
        onArticleUpdate && onArticleUpdate(newArticle, false);

        showToast(<Trans>articleReplacedString</Trans>);
      } else {
        onArticleUpdate && onArticleUpdate(article, false);
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "16px",
          marginTop: "8px",
          marginLeft: "68px",
        }}
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <SkapaQuantityStepper {...QSProps} />
        {!selectArticleIdsMode && (
          <>
            <Button
              small={true}
              iconOnly={true}
              ssrIcon={switchArticle}
              onClick={async () => {
                await handleReplaceArticle();
                logEvent("ce:MM_articles_selected_switchproduct");
              }}
            />
            <Button
              small={true}
              iconOnly={true}
              ssrIcon={trashcan}
              onClick={() => {
                setTrashcanModalOpen(true);
                stopPropagation && stopPropagation(true);
                logEvent("ce:MM_articles_selected_deleteproduct");
              }}
            />
          </>
        )}
      </div>
      <DeleteArticleModal
        modalOpen={trashcanModalOpen}
        article={article}
        onModalOpenChange={setTrashcanModalOpen}
        postDeleteFunction={async () => {
          await createAutoGeneratedTaskWithReason(Reasons.REMOVE_ARTICLE, "");
          showToast(<Trans>articleDeletedString</Trans>);
        }}
      />
    </>
  );
};
